  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-12-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert fissure Marseille</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Expertise fissure à Marseille - Le phénomène de fissurations sur ouvrage</h1>

<p>Les fissures sur ouvrage sont des pathologies courantes de la construction.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert fissure Marseille" />
</div>

<p>On distingue les fissures esthétiques, sans conséquences graves, des fissures structurelles qui pour ces dernières, affectent de façon conséquente la structure et peuvent, si elles ne sont pas traitées, menacer ruine de l’ouvrage. </p>

<p>Les fissures peuvent affecter tout type d’ouvrage, que ce soit un ouvrage maçonné, un ouvrage en béton ou un ouvrage en bois.</p>

<p>Il n’est pas aisé pour un particulier de savoir si des fissures sont graves et comment remédier au problème.</p>

<p>L’expert fissure est un professionnel de la construction qui maîtrise les techniques de la construction et les pathologies de la construction.</p>
<p>Par son analyse, il détermine les causes et origines des fissures afin de définir la solution la plus adaptée afin de réparer de façon pérenne un ouvrage fissuré. </p>
<p> </p>
<h2>Notre prestation expertise fissure à Marseille </h2>

<p>Vous avez constaté des fissures sur votre bâti ? </p>
<p>Vous avez besoin d’un avis technique pour répondre à vos questions les plus légitimes ?</p>
<p>Vous avez besoin d’être assisté techniquement suite à un litige avec un constructeur, un bailleur, ou une assurance ?</p>

<p>Notre expertise privée et indépendante met à votre disposition les compétences d’un expert construction certifié pour tout avis technique.</p>

<p>Notre expert vous aide à déterminer les causes et origines des fissures et vous oriente sur les solutions techniques les plus adaptées à une réparation durable.</p>


<p> </p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un expert fissure</a>
    </div>
</div>
<p> </p>
<p> </p>



                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-fissure-marseille/' className='active'>Expert fissure Marseille</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details